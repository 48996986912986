<template>
  <div class="feed-body">
    <div class="feed-contents">
      <div class="d-flex flex-row w-100 justify-between mt-5 mb-3">
        <span class="white--text font-30 app-medium-font">{{
          $t("donate")
        }}</span>
      </div>
      <v-progress-circular
        indeterminate
        color="amber"
        v-if="loading"
        class="mt-10"
      />
      <div v-else class="w-100 d-flex">
        <div>
          <v-card @click="goToProfile" class="rounded-lg">
            <div
              class="d-flex align-center justify-start h-100 pa-5 d-flex flex-row"
              v-if="agency"
            >
              <agency-avatar
                :img="agency.midThumbnail ? agency.midThumbnail : agency.image"
                :name="agency.name"
                :size="60"
              />
              <span
                class="primary-color font-20 app-bold-font text-left text-ellipsis break-word line-height-15 force-height-30 d-flex align-center ml-4"
              >
                {{ agency.name }}
              </span>
            </div>
          </v-card>
          <div class="d-flex w-100 pt-4">
            <v-card class="w-100">
              <v-card-title>
                <div
                  style="width: 200px"
                  class="d-flex flex-end justify-end pr-5 font-14"
                >
                  Donation Amount:
                </div>
                <span class="font-16" style="width: 100px">
                  {{ formatCurrency(this.$route.query.amount) }}
                </span>
              </v-card-title>
              <v-card-title class="pt-0">
                <div
                  style="width: 200px;"
                  class="d-flex flex-end justify-end pr-5 font-14"
                >
                  Fee:
                </div>
                <span class="font-16">
                  {{ formatCurrency(this.$route.query.amount * 0.05) }}
                </span>
              </v-card-title>
              <v-divider />
              <v-card-title>
                <div
                  style="width: 200px;"
                  class="d-flex flex-end justify-end pr-5 font-14"
                >
                  Amount agency receive:
                </div>
                <span class="font-16">
                  {{ formatCurrency(this.$route.query.amount * 0.95) }}
                </span>
              </v-card-title>
            </v-card>
          </div>
          <div class="d-flex w-100 py-4">
            <v-card class="w-100">
              <v-card-text>
                <v-textarea
                  label="Description"
                  solo
                  flat
                  outlined
                  v-model="description"
                  style="font-family:'Poppins-Regular';"
                  readonly
                />
              </v-card-text>
            </v-card>
          </div>
        </div>
        <div class="flex-grow pl-4">
          <v-card>
            <v-card-text v-if="elementsOptions.clientSecret">
              <stripe-element-payment
                ref="paymentRef"
                :pk="publishableKey"
                :elements-options="elementsOptions"
                :confirm-params="confirmParams"
              />
            </v-card-text>
            <v-card-title v-else class="d-flex align-center justify-center">
              <v-progress-circular indeterminate color="amber" class="my-10" />
            </v-card-title>
            <v-card-actions>
              <v-btn block class="my-4" @click="submit">Donate</v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </div>
    </div>
    <v-snackbar v-model="snackbar">
      {{ errorMessage }}
    </v-snackbar>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import AgencyAvatar from "@/components/agency/AgencyAvatar.vue";
import { StripeElementPayment } from "@vue-stripe/vue-stripe";
export default {
  components: { AgencyAvatar, StripeElementPayment },
  data() {
    return {
      loading: false,
      errorMessage: null,
      snackbar: false,
      agency: null,
      amount: null,
      publishableKey: process.env.VUE_APP_STRIPE_PUBLIC_KEY,
      elementsOptions: {
        appearance: {}, // appearance options
        clientSecret: "",
      },
      confirmParams: {
        return_url: process.env.VUE_APP_STRIPE_RETURN_URL,
      },
      description: "",
    };
  },
  methods: {
    ...mapActions({
      getAgency: "agency/getAgency",
      createPaymentIntent: "donation/createPaymentIntent",
    }),
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    },
    goToProfile() {
      let routeData = this.$router.resolve({
        name: "agency-profile-details",
        params: { id: this.$route.query.id },
      });
      window.open(routeData.href, "_blank");
    },
    onRoundAmount() {
      try {
        if (!this.amount) return;
        this.amount = parseFloat(`${this.amount}`).toFixed(2);
      } catch (error) {
        console.log(error);
      }
    },
    formatCurrency(numberString) {
      const number = parseFloat(numberString);
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(number);
    },
    submit() {
      this.$refs.paymentRef.submit();
    },
    initPaymentIntent() {
      this.createPaymentIntent({
        description: this.description,
        amount: parseFloat(`${this.amount * 100}`),
        receiver: this.agency._id,
      })
        .then((res) => {
          this.elementsOptions.clientSecret = res.paymentIntent.client_secret;
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },
  },
  computed: {
    isValidAmount() {
      // Check if amount is null or empty
      if (
        this.amount === null ||
        this.amount === undefined ||
        this.amount === ""
      ) {
        return false;
      }

      // Check if amount is a valid number and greater than or equal to 0
      const num = parseFloat(this.amount);
      if (isNaN(num) || num < 0) {
        return false;
      }

      return true;
    },
  },
  mounted() {
    this.loading = true;
    this.amount = this.$route.query.amount;
    this.description = this.$route.query.description;

    if (!this.isValidAmount || !this.$route.query.id) {
      this.$router.replace({
        name: "donation-set-amount",
        query: this.$route.query,
      });
      return;
    }

    this.getAgency(this.$route.query.id)
      .then((res) => {
        this.loading = false;
        this.agency = res;
        this.initPaymentIntent();
      })
      .catch((error) => {
        this.loading = false;
        if (error.response == undefined) {
          console.log(error);
        } else {
          this.showErrorMessage(error.response.data.message);
        }
      });
  },
};
</script>
<style scoped>
.feed-body {
  width: 100%;
  min-width: 200px;
  margin-left: 0px;
  margin-right: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.feed-contents {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-top: 20px;
}
</style>
